import { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'next-i18next';
import { Button, ThemeContext } from '@forma/forma-ui-kit';
// import ClientWrap from '@/components/ClientWrap';
import Link from '@/components/Link';
// import Video from '@/components/Video';

import styles from './main-banner.module.css';

const lkUrl = process.env.LK_URL;

const MainBanner = ({ title, description, image }) => {
  const { t, i18n } = useTranslation();
  const { viewport } = useContext(ThemeContext);
  // const [ open, setOpen ] = useState(false);
  const isPhone = viewport === 'phone';

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
        {description && <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />}
        {!isPhone && (
          <div className={styles.buttons}>
            <Button
              as={Link}
              viewStyle="primary"
              href={`${lkUrl}/register?lng=${i18n.language}`}
              className={styles.button}
              icon={<ReactSVG src="/icons/arrow-right.svg" wrapper="span" />}
            >
              {t('try_free_trial_days')}
            </Button>
            {/* <Button
              as={Link}
              viewStyle="primary"
              href="/request"
              icon={<ReactSVG src="/icons/arrow-right.svg" wrapper="span" />}
              className={styles.button}
              shadow
            >
              {t('request_online_demo')}
            </Button> */}
          </div>
        )}
      </div>
      <div className={styles.right}>
        {/* {isPhone ? (
          <button className={styles.play} onClick={() => setOpen(true)}>
            <ReactSVG src="/icons/play-large.svg" className={styles.playIcon}/>
          </button>
        ) : (
          <ClientWrap>
            <Tooltip
              control={
                <button className={styles.play} onClick={() => setOpen(true)}>
                  <ReactSVG src="/icons/play-large.svg" className={styles.playIcon}/>
                </button>
              }
              offsetX={50}
              offsetY={0}
              position={['bottom left', 'bottom right']}
            >
              {t('watch_video')}
            </Tooltip>
          </ClientWrap>
        )} */}
        <img src={image} className={styles.image} alt="" width="700px" height="520px" />
      </div>
      {isPhone && (
        <div className={styles.buttons}>
          {/* <Button
            as={Link}
            viewStyle="primary"
            href="/request"
            icon={<ReactSVG src="/icons/arrow-right.svg" wrapper="span" />}
            className={styles.button}
          >
            {t('request_online_demo')}
          </Button> */}
          <Button
            as={Link}
            viewStyle="primary"
            href={`${lkUrl}/register?lng=${i18n.language}`}
            className={styles.button}
            icon={<ReactSVG src="/icons/arrow-right.svg" wrapper="span" />}
          >
            {t('try_free_trial_days')}
          </Button>
        </div>
      )}

      {/* <ClientWrap>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          size="large"
        >
          <Video
            className={styles.video}
            src="https://static.forma.today/videos/Forma-Anim_1920-1080_All_5-9_H264.mp4"
          />
        </Modal>
      </ClientWrap> */}
    </div>
  );
};

export default MainBanner;
