import { useContext } from 'react';
import { useTranslation } from 'next-i18next';
import { Button, ThemeContext } from '@forma/forma-ui-kit';
import { EconTable, TotalBlock, TotalTable } from './economyComponents';

import styles from './economy-table.module.css';

const MISTAKES = 30;
const MISTAKES_FORMA = 0;

const formatValue = (value, currency) => new Intl.NumberFormat(currency.code).format(value.toFixed(2));

const EconomyTable = ({ docs, workers, salary, onClose }) => {
  const { t } = useTranslation();
  const { currencies, lang } = useContext(ThemeContext);
  const currency = currencies[lang];
  const { viewport } = useContext(ThemeContext);

  const workMonthPrice = (salary/21/8/100*145);

  const withoutFormaMistakes = docs*workers/100*MISTAKES;
  const withoutFormaHours = (docs*workers*15 + 30 * withoutFormaMistakes) / 60;
  const withoutFormaTotal = withoutFormaHours * workMonthPrice;

  const withFormaMistakes = docs*workers/100*MISTAKES_FORMA;
  const withFormaHours = (docs*workers*1 + 30 * withFormaMistakes) / 60;
  const withFormaTotal = withFormaHours * workMonthPrice;

  return (
    <div className={styles.root}>
      <EconTable
        rows={[
          { label: t('salary'), value: formatValue(salary, currency) },
          { label: t('work_month_price'), value: formatValue(workMonthPrice, currency) },
          { label: t('docs_in_month'), value: docs },
          { label: t('time_per_fill'), value: 15 },
          { label: t('time_per_fill_forma'), value: 1 },
          { label: t('coworkers_count'), value: workers },
          { label: t('count_mistakes'), value: MISTAKES/100 },
          { label: t('count_mistakes_forma'), value: MISTAKES_FORMA/100 },
          { label: t('create_docs_per_month'), value: workers*docs },
          { label: t('ndfl_with_val'), value: '13.00%' },
          { label: t('penssion_with_val'), value: '22.00%' },
          { label: t('medicine_with_val'), value: '5.10%' },
          { label: t('disability_with_val'), value: '2.90%' },
          { label: t('injury_with_val'), value: '2.00%' },
          { label: t('total_percent'), value: '45.00%' },
          { label: t('work_on_mistakes'), value: 30 },
        ]}
      />
      <div className={styles.totalTableWrap}>
        {viewport === 'phone' ? (
          <>
            <TotalBlock
              title={t('without_forma')}
              items={[
                { label: t('working_hours'), value: formatValue(withoutFormaHours, currency) },
                { label: t('mistakes'), value: formatValue(withoutFormaMistakes, currency) },
                { label: t('price_in_currency'), value: formatValue(withoutFormaTotal, currency) }
              ]}
            />
            <TotalBlock
              title={t('with_forma')}
              items={[
                { label: t('working_hours'), value: formatValue(withFormaHours, currency) },
                { label: t('mistakes'), value: formatValue(withFormaMistakes, currency) },
                { label: t('price_in_currency'), value: formatValue(withFormaTotal, currency) }
              ]}
            />
            <TotalBlock
              title={t('economy')}
              items={[
                { label: t('working_hours'), value: formatValue(withoutFormaHours - withFormaHours, currency) },
                { label: t('mistakes'), value: formatValue(withoutFormaTotal - withFormaTotal, currency) },
                { label: t('price_in_currency'), value: formatValue(withoutFormaMistakes - withFormaMistakes, currency) }
              ]}
            />
          </>
        ) : (
          <TotalTable
            head={[ '', t('working_hours'), t('mistakes'), t('price_in_currency') ]}
            rows={[
              {
                label: t('without_forma'),
                hours: formatValue(withoutFormaHours, currency),
                mistakes: formatValue(withoutFormaMistakes, currency),
                value: formatValue(withoutFormaTotal, currency),
              }, {
                label: t('with_forma'),
                hours: formatValue(withFormaHours, currency),
                mistakes: formatValue(withFormaMistakes, currency),
                value: formatValue(withFormaTotal, currency),
              }, {
                label: t('economy'),
                hours: formatValue(withoutFormaHours - withFormaHours, currency),
                mistakes: formatValue(withoutFormaMistakes - withFormaMistakes, currency),
                value: formatValue(withoutFormaTotal - withFormaTotal, currency),
              }
            ]}
          />
        )}
      </div>

      <div className={styles.totalRow}>
        <div className={styles.totalLabel}>{t('economy_per_month')}</div>
        <div className={styles.totalValue}>{formatValue(withoutFormaTotal - withFormaTotal, currency)}</div>
      </div>
      <div className={styles.totalRow}>
        <div className={styles.totalLabel}>{t('economy_per_year')}</div>
        <div className={styles.totalValue}>{formatValue((withoutFormaTotal - withFormaTotal)*12, currency)}</div>
      </div>

      <div className={styles.footer}>
        <div className={styles.notice}>{t('economy_table_notice')}</div>
        <Button className={styles.button} viewStyle="primary" onClick={onClose}>{t('ok')}</Button>
      </div>
    </div>
  );
};

export default EconomyTable;
