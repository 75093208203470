import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';

import styles from './year-select.module.css';
import { ReactSVG } from 'react-svg';

const YearSelect = ({ onChange }) => {
  const { t } = useTranslation();
  const options = [{ label: t('one_year'), value: 12 }, { label: t('one_month'), value: 1 }];
  const [ selected, setSelected ] = useState(1);

  useEffect(() => {
    onChange(options[selected].value);
    // eslint-disable-next-line
  }, [selected]);

  return (
    <div className={styles.root}>
      <ReactSVG
        className={styles.arrow}
        src="/icons/chevron-left.svg"
        width="16px"
        height="16px"
        onClick={() => setSelected(prev => prev === 0 ? options.length-1 : prev-1)}
        tabIndex="0"
      />
      <span className={styles.text}>{options[selected].label}</span>
      <ReactSVG
        className={styles.arrow}
        src="/icons/chevron-right.svg"
        width="16px"
        height="16px"
        onClick={() => setSelected(prev => prev+1 >= options.length ? 0 : prev+1)}
        tabIndex="0"
      />
    </div>
  );
};

export default YearSelect;
