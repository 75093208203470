import classNames from 'classnames';
import { Button } from '@forma/forma-ui-kit';
import Link from '@/components/Link';

import styles from './templates-banner.module.css';

const TemplatesBlockButton = ({ name, image, link }) => {
  return (
    <div className={classNames(styles.block, styles.blockButton)} style={{ backgroundImage: `url(${image})` }}>
      <div className={styles.blockButtons}>
        <Button as={Link} viewStyle="primary" href={link}>{name}</Button>
      </div>
    </div>
  );
};

const TemplatesBlock = ({ title, description, image }) => {
  return (
    <div className={styles.block} style={{ backgroundImage: `url(${image})` }}>
      <div className={styles.blockTitle} dangerouslySetInnerHTML={{ __html: title }}></div>
      <div className={styles.blockDescr}>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </div>
  );
};

const TemplatesBanner = ({ items }) => {
  if (!items) return null;

  return (
    <div className={styles.root}>
      <div className={styles.grid}>
        {items.map(({ button, ...item }, index) => (
          <div className={styles.item} key={`item_${index}`}>
            <TemplatesBlock {...item} />
            {button &&
              <TemplatesBlockButton {...button} />
            }
          </div>
        ))}
      </div>
    </div>
  );
};

export default TemplatesBanner;
