import styles from './economy-table.module.css';

export const EconTable = ({ rows }) => (
  <table className={styles.table} cellSpacing={0}>
    <tbody>
      {rows.map(({ label, value }, index) => (
        <tr key={index}>
          <td>{label}</td>
          <td className={styles.tableValue}>{value}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export const TotalTable = ({ head, rows }) => (
  <table className={styles.totalTable} cellSpacing={0}>
    <thead>
      <tr>
        {head.map((item, index) => <th key={index}>{item}</th>)}
      </tr>
    </thead>
    <tbody>
      {rows.map(({ label, hours, mistakes, value }, index) => (
        <tr key={index}>
          <td>{label}</td>
          <td className={styles.totalTableValue}>{hours}</td>
          <td className={styles.totalTableValue}>{mistakes}</td>
          <td className={styles.totalTableValue}>{value}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export const TotalBlock = ({ title, items }) => (
  <div className={styles.totalBlock}>
    <div className={styles.totalBlockTitle}>{title}</div>
    {items.map(({ label, value }, index) => (
      <div className={styles.totalBlockRow} key={index}>
        <div className={styles.totalBlockLabel}>{label}</div>
        <div className={styles.totalBlockValue}>{value}</div>
      </div>
    ))}
  </div>
);
