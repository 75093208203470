import { useContext } from 'react';
import Slider from 'react-slick';
import { Trans } from 'next-i18next';
import { Button, ThemeContext } from '@forma/forma-ui-kit';
import Link from '@/components/Link';

import styles from './advantages-banner.module.css';

const AdvantagesBlock = ({ name, list, content, image, button }) => {
  return (
    <div className={styles.block} style={{ backgroundImage: image && `url(${image})` }}>
      {name && <div className={styles.blockTitle} dangerouslySetInnerHTML={{ __html: name }}></div>}
      {(content || list) && (
        <div className={styles.blockDescr}>
          {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
          {list && (
            <ul className={styles.blockList}>
              {list.map((item, listIndex) =>
                <li className={styles.blockListItem} key={`list_item_${listIndex}`} dangerouslySetInnerHTML={{ __html: item }} />
              )}
            </ul>
          )}
        </div>
      )}
      {button && (
        <div className={styles.blockButtons}>
          <img src={button.icon} className={styles.blockButtonsIcon} alt="" />
          <Button viewStyle="primary" as={Link} href={button.link}>{button.name}</Button>
        </div>
      )}
    </div>
  );
};

const AdvantagesBanner = ({ items }) => {
  const { viewport } = useContext(ThemeContext);

  if (!items) return null;

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>
        <Trans
          i18nKey="advantages_forma"
          components={{ accent: <span className="accent-text" /> }}
        />
      </h2>
      {viewport === 'phone' ? (
        <Slider className={styles.slider} arrows={false} infinite={false} dots={true}>
          {items.map(({ button, ...item }, index) => !button && (
            <div className={styles.item} key={`item_${index}`}>
              <AdvantagesBlock {...item} />
            </div>
          ))}
        </Slider>
      ) : (
        <div className={styles.grid}>
          {items.map((item, index) => (
            <div className={styles.item} key={`item_${index}`}>
              <AdvantagesBlock {...item} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdvantagesBanner;
