import { useEffect, useRef, useState } from 'react';
import { Trans } from 'next-i18next';

import styles from './how-to-banner.module.css';

const HowToUseBanner = ({ items }) => {
  const listRef = useRef(null);
  const observerRef = useRef(null);
  const [ barHeight, setBarHeight ] = useState(0);
  const [ robotTop, setRobotTop ] = useState(0);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        const target = entry.target;
        const children = target.children[0];
        setBarHeight(children.offsetTop);
        setRobotTop(children.offsetTop);
      }
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 1
    });
  }, []);

  useEffect(() => {
    if (listRef.current) {
      for (const i of listRef.current.childNodes) {
        observerRef.current.observe(i);
      }
    }

    return () => observerRef.current.disconnect();
  }, [listRef]);

  if (!items) return null;

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <h3 className={styles.title}>
          <Trans
            i18nKey="how_to_use_forma"
            components={{ accent: <span className="accent-text" /> }}
          />
        </h3>
        <div className={styles.blocks}>
          {items.map(({ image }, index) => (
            <div className={styles.block} key={`block_${index}`} style={{ backgroundImage: `url(${image})` }} />
          ))}
        </div>
      </div>
      <div className={styles.right}>
        <img className={styles.robot} src="/images/how_to_use/robot.svg" alt="Robot" style={{ top: robotTop + 'px', opacity: robotTop ? 1 : 0 }} />
        <div className={styles.progress}>
          <div className={styles.bar} style={{ height: barHeight + 'px' }} />
        </div>
        <div className={styles.items} ref={listRef}>
          {items.map(({ title, description }, index) => (
            <div className={styles.item} key={`item_${index}`}>
              <div className={styles.itemNumber}>{index+1}</div>
              <div className={styles.itemContent}>
                <div className={styles.itemTitle}>{title}</div>
                <div className={styles.itemDescription}>{description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowToUseBanner;
