import { useContext, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import { ThemeContext } from '@forma/forma-ui-kit';
import useDebounce from '@/hooks/useDebounce';

import styles from './video-banner.module.css';

const Item = ({ name, video }) => {
  const [ isReady, setIsReady ] = useState(false);

  return (
    <div className={styles.videoWrapper}>
      <video
        width="900"
        height="512"
        muted
        playsInline
        loop
        autoPlay
        onCanPlay={() => setIsReady(true)}
        className={styles.video}
        src={video}
      />
      {!isReady && (
        <div className={styles.loader}>
          <ReactSVG src="/icons/loading.svg" className={styles.loaderIcon} />
        </div>
      )}
    </div>
  );
};

const VideoBanner = ({ items }) => {
  const { t } = useTranslation();
  const slider = useRef(null);
  const sliderWrapper = useRef(null);
  const { viewport } = useContext(ThemeContext);
  const isTabletOrPhone = ['tablet', 'phone', 'tabletS'].includes(viewport);
  const [ current, setCurrent ] = useState(0);

  const handleResize = useDebounce(() => resizeItems(), 100, true);
  const resizeItems = () => {
    if (sliderWrapper.current) {
      const items = sliderWrapper.current.getElementsByClassName('slick-slide');
      const height = sliderWrapper.current.clientHeight;
      for (const item of items) {
        item.style.height = height + 'px';
      }
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);

    //eslint-disable-next-line
  }, [slider.current]);

  const handleClickNavItem = (index) => {
    if (slider.current) slider.current.slickGoTo(index);
  };

  const handleChangeSlide = (index) => {
    setCurrent(index);
  };

  if (!items) return null;

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.contentContainer}>
          <h2 className={styles.title}>{t('slider_title')}</h2>
          <div className={styles.sliderWrapper} ref={sliderWrapper}>
            <Slider
              className={styles.slider}
              arrows={false}
              infinite={false}
              vertical={!isTabletOrPhone}
              verticalSwiping={!isTabletOrPhone}
              lazyLoad="ondemand"
              beforeChange={(current, next) => handleChangeSlide(next)}
              adaptiveHeight={!isTabletOrPhone}
              ref={slider}
            >
              {items.map(({ name, video }, index) => (
                <Item name={name} video={video} key={index} />
              ))}
            </Slider>
          </div>
        </div>
        <div className={styles.dots}>
          {items.map((item, index) => (
            <div
              className={classNames(styles.dot, current === index && styles.active)}
              onClick={() => handleClickNavItem(index)}
              key={`dots_${index}`}
            />
          ))}
        </div>
      </div>
      <div className={styles.nav}>
        {items.map(({ icon, name }, index) => (
          <div
            className={classNames(styles.navItem, current === index && styles.active)}
            onClick={() => handleClickNavItem(index)}
            key={`nav_${index}`}
          >
            <img className={styles.navItemIcon} src={icon} alt={name} />
            <span className={styles.navItemText}>
              {name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoBanner;
