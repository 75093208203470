import { Trans } from 'next-i18next';

import styles from './how-to-banner.module.css';

const HowToUseBannerMobile = ({ items }) => {
  if (!items) return null;

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <h3 className={styles.title}>
          <Trans
            i18nKey="how_to_use_forma"
            components={{ accent: <span className="accent-text" /> }}
          />
        </h3>
        <div className={styles.blocks}>
          {items.map(({ title, description, image }, index) => (
            <div className={styles.item} key={`item_${index}`}>
              <div className={styles.block} style={{ backgroundImage: `url(${image})` }} />
              <div className={styles.itemNumber}>{index+1}</div>
              <div className={styles.itemContent}>
                <div className={styles.itemTitle}>{title}</div>
                <div className={styles.itemDescription}>{description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowToUseBannerMobile;
